<template>
  <el-form >
    <el-form-item label="文章封面" prop="coverId">
      <el-upload ref="field118" :limit="1" :action="upload.url" accept=".png,.jpg,.gif" list-type="picture-card"
        :on-success="handleUploadSuccess" :before-upload="handleBeforeUpload" :on-error="handleUploadError"
        :file-list="imgList" :headers="upload.headers" name="file" :show-file-list="false"
        style="display: inline-block; vertical-align: top">
        <div class="el-upload__tip" style="color: red" slot="tip">
          提示：仅允许上传“jpg”，“png”，“gif"等格式文件，并且文件大小不能超过500k！
        </div>
        <el-image v-if="!logoUrl" :src="logoUrl">
          <div slot="error" class="image-slot">
            <i class="el-icon-plus" />
          </div>
        </el-image>
        <div v-else>
          <el-image :src="logoUrl" :style="`width:146px;height:146px;`" fit="fill" />
          <div class="mask addmask">
            <div class="actions">
              <span title="预览" @click.stop="upload.dialogVisibleTwo = true">
                <i class="el-icon-zoom-in" />
              </span>
              <span title="移除" @click.stop="removeImage">
                <i class="el-icon-delete" />
              </span>
            </div>
          </div>
        </div>
      </el-upload>
      <!-- 预览本地图片 -->
      <el-dialog :visible.sync="upload.dialogVisibleTwo" title="预览" width="800" append-to-body>
        <img :src="logoUrl" style="display: block; max-width: 100%; margin: 0 auto;">
      </el-dialog>
    </el-form-item>
  </el-form>
  
</template>

<script>
  export default {
    data() {
      return {
        upload: {
          dialogVisibleTwo: false,
          // 是否禁用上传
          isUploading: false,
          // 设置上传的请求头部
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token")
          },
          // 上传的地址
          url: process.env.VUE_APP_BASE_API + "/common/upload",
        },
        imgList: [],
        logoUrl: "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/3f7eb5b6-b193-4821-969f-d930d55ce260.png"
      }
    },
    methods: {

      // 图片上传==================获取到coverId,拿到图片路径回显
      handleUploadSuccess(res) {
        this.$emit('input', res.url)
        this.loading.close()
        this.formData.coverId = res.fileId
        this.logoUrl = process.env.VUE_APP_BASE_API + res.url
      },
      handleBeforeUpload() {
        this.loading = this.$loading({
          lock: true,
          text: '上传中',
          background: 'rgba(0, 0, 0, 0.7)',
        })
      },
      handleUploadError() {
        this.$message({
          type: 'error',
          message: '上传失败',
        })
        this.loading.close()
      },
      // 图片清空=================注意
      removeImage() {
        this.logoUrl = ''
        this.imgList = []
        this.formData.coverId = ""
        //   this.$refs.field118.clearFiles()
      },

    },
  }
</script>

<style>
</style>




